<template>
  <v-card elevation="2" style="width: 300px;">
    <v-card-title>Forgot Password?</v-card-title>
    <v-card-text>
      Please contact management for a password reset. Once reset you'll receive an automated email with instructions on how to proceed.
    </v-card-text>
    <v-card-actions>
      <v-btn block color="primary" elevation="2" @click="$router.push({ name: 'signin' })">
        Back to Sign in
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'forgotAuth'
}
</script>

<style scoped>

</style>
